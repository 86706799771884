import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchShowImagesStartAction } from 'store/shows/images/types';
import TMDbService from '~/services/tmdbService';
import { fetchShowImagesFailure, fetchShowImagesSuccess } from 'store/shows/images/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* fetchShowImagesSaga(action: IFetchShowImagesStartAction): Generator {
  try {
    const showImages: any = yield call([API, API.getShowImages], action.payload.seriesId, action.payload.seasonNumber, action.payload.episodeNumber);
    yield put(fetchShowImagesSuccess(showImages));
  } catch (error) {
    yield put(
      Notifications.error({
        title: 'Movie images',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
    yield put(fetchShowImagesFailure());
  }
}

export default function*() {
  yield all([takeLatest(ActionTypes.FETCH_SHOW_IMAGES_START, fetchShowImagesSaga)]);
}