import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import './MyAccount.css';
import { useAuthState } from 'react-firebase-hooks/auth'; // Ensure this is correctly imported
import SendResetEmailButton from './SendResetEmailButton';
import { useHistory } from 'react-router-dom';
import AppFooter from 'components/partials/AppFooter';
const SERVER_URL = 'https://recapflix-e82c0158527d.herokuapp.com';

const MyAccountPage = () => {
    const history = useHistory(); // Add this line
    const [userData, setUserData] = useState({ email: '', full_name: '', credit_count: 0, stripe_customer_id: null });
    const auth = getAuth();
    const [user] = useAuthState(auth); // This line automatically manages the user state

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((idToken) => {
                    fetchUserDetails(user.email, idToken);
                });
            }
        });
        return () => unsubscribe();
    }, [auth]);


const handleAccessStripePortal = async () => {
    if (user) {
        const userToken = await user.getIdToken(true); // Assuming you've got the user's token
        const response = await fetch(`${SERVER_URL}/create-customer-portal-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
            },
        });
        if (!response.ok) {
            // Handle error - e.g., display a notification
            return;
        }

        const { url } = await response.json();
        window.location.href = url; // Redirect the user to the Stripe Customer Portal
    }
};


const fetchUserDetails = (email : any, idToken : any) => {
    const url = `${SERVER_URL}/api/users/${encodeURIComponent(email)}`;

    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + idToken,
            'Content-Type': 'application/json',
        },
    })
    .then(response => response.json())
    .then(data => {
        setUserData({
            email: email,
            full_name: data.full_name || '',
            credit_count: data.credit_count,
            stripe_customer_id: data.stripe_customer_id,
        });
    })
    .catch(error => {
    });
};


    const handleAddCredits = () => {
        history.push('/purchase'); // Update this line to navigate to the purchase page
    };

    return (
        <div className="account-page-container">
            <div className="account-container">
                <h1 className="gradient-text">  <span>Account</span></h1>
                <div className="account-details">
                <h2>Account Details</h2>
                    <div className="account-info">
                        <span className="label">Email:</span>
                        <span className="value">{userData.email}</span>
                    </div>
                    <div className="account-info">
                        <span>Password: </span>
                        <SendResetEmailButton />
                    </div>
                    <div className="account-info">
                    <span className="label">Name:</span>
                        <span className="value">{userData.full_name}</span>                    
                    </div>                
                </div>
                <hr />
                <div className="support-section">
                <h2>Need help?</h2>
                    <p className="support-email">support@recapflix.com</p>                
                </div>
            </div>
            <div className="credits-summary-container">
                <h1 className="gradient-text">  <span>Credit Balance</span></h1>
                <div className="credits-center-content">
                <h2>{userData.credit_count} credits</h2>
                <button className="button" onClick={handleAddCredits}>Add Credits</button> {/* Add Credits Button */}
                {userData.stripe_customer_id && (
                        <button className="button stripe-portal-button" onClick={handleAccessStripePortal}>Manage Billing</button>  )}              </div>
            </div>
        </div>
        
    );
};

export default MyAccountPage;
