import { all, call, debounce, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchpopularContentStartAction, ISetPopularSearchPage } from 'store/movie/popular/types';
import TMDbService from '~/services/tmdbService';
import { fetchpopularContentFailure, fetchpopularContentSuccess } from 'store/movie/popular/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* savepopularContent(page = 1, shouldConcat = false): Generator {
  try {
    const popularContent: any = yield call(API.getPopularContent, page);
    yield put(
      fetchpopularContentSuccess({
        movies: popularContent,
        shouldConcat,
      })
    );
  } catch (error) {
    yield put(fetchpopularContentFailure());
    yield put(
      Notifications.error({
        title: 'Popular movies',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
  }
}

function* fetchpopularContentSaga(action: IFetchpopularContentStartAction) {
  yield savepopularContent(1, false);
}

function* fetchpopularContentWithPageSaga(action: ISetPopularSearchPage) {
  const page = action.payload;

  yield savepopularContent(page, true);
}

export default function*() {
  yield all([
    debounce(150, ActionTypes.SET_POPULAR_SEARCH_PAGE, fetchpopularContentWithPageSaga),
    takeLatest(ActionTypes.FETCH_POPULAR_MOVIES_START, fetchpopularContentSaga),
  ]);
}
