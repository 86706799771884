import { ActionTypes } from 'store/shows/details/types';
import { IShowDetails } from 'common/types/show-details';

export function fetchShowDetailsStart(payload: number) {
  return {
    type: ActionTypes.FETCH_SHOW_DETAILS_START,
    payload,
  };
}

export function fetchShowDetailsSuccess(payload: IShowDetails) {
  return {
    type: ActionTypes.FETCH_SHOW_DETAILS_SUCCESS,
    payload,
  };
}

export function fetchShowDetailsFailure() {
  return {
    type: ActionTypes.FETCH_SHOW_DETAILS_FAILURE,
  };
}

export function clearShowDetails() {
  return {
    type: ActionTypes.CLEAR_SHOW_DETAILS,
  };
}
