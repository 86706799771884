import React, { FunctionComponent } from 'react';
import './PrivacyPolicy.css'; // Ensure the CSS file is imported

const PrivacyPolicy: FunctionComponent = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last Updated: March 8, 2024</p>
      <h2>1. Introduction</h2>
      <p>This Privacy Policy explains how Recapflix, a service provided by Subhash Ventures, collects, uses, and shares information about you when you use our website, recapflix.com.</p>
      <h2>2. Information We Collect</h2>
      <ul>
        <li><strong>Personal Information:</strong> We collect information you provide directly to us, such as when you create an account, purchase credits, or communicate with us. This information may include your name, email address, payment information, and any other information you choose to provide.</li>
        <li><strong>Usage Information:</strong> We collect information about your interactions with our Service, including the pages you view, your searches, and other actions on the Service.</li>
      </ul>
      <h2>3. Use of Information</h2>
      <p>We use the information we collect to provide, maintain, and improve our Service, to process transactions, and to communicate with you regarding your account and the Service.</p>
      <h2>4. Sharing of Information</h2>
      <p>We do not share your personal information with third parties, except as necessary to provide our Service, comply with the law, or protect our rights.</p>
      <h2>5. Data Security</h2>
      <p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.</p>
      <h2>6. Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</p>
      <h2>7. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at support@recapflix.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
