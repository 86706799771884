import { ActionTypes } from 'store/shows/credits/types';
import { IShowCredits } from 'common/types/show-credits';

export function fetchShowCreditsStart(payload: number) {
  return {
    type: ActionTypes.FETCH_SHOW_CREDITS_START,
    payload,
  };
}

export function fetchShowCreditsSuccess(payload: IShowCredits) {
  return {
    type: ActionTypes.FETCH_SHOW_CREDITS_SUCCESS,
    payload,
  };
}

export function fetchShowCreditsFailure() {
  return {
    type: ActionTypes.FETCH_SHOW_CREDITS_FAILURE,
  };
}

export function clearShowCredits() {
  return {
    type: ActionTypes.CLEAR_SHOW_CREDITS,
  };
}
