import { all } from 'redux-saga/effects';

import searchSaga from 'store/search/saga';
import popularContentSaga from 'store/movie/popular/saga';
import topRatedContentSaga from 'store/movie/toprated/saga';
import recommendedMoviesSaga from 'store/movie/recommended/saga';
import recommendedShowSaga from 'store/shows/recommended/saga';
import movieDetailsSaga from 'store/movie/details/saga';
import movieCreditsSaga from 'store/movie/credits/saga';
import movieImagesSaga from 'store/movie/images/saga';
import showImagesSaga from 'store/shows/images/saga';
import showCreditsSaga from 'store/shows/credits/saga';
import genresSaga from 'store/genres/saga';
import personDetailsSaga from 'store/person/saga';
import initializeAppSaga from 'store/init/saga';
import showDetailsSaga from './shows/details/saga';
import seasonsSaga from 'store/shows/seasons/saga';
import searchSubtitlesSaga from 'store/subtitle/saga';
import downloadSubtitleSaga from 'store/subtitle/saga';
import searchTVSubtitlesSaga from 'store/subtitle/saga';
import convertSubtitleSaga from 'store/subtitle/saga';


export default function* rootSaga() {
  yield all([
    searchSaga(),
    popularContentSaga(),
    topRatedContentSaga(),
    genresSaga(),
    movieImagesSaga(),
    showImagesSaga(),
    showDetailsSaga(),
    movieDetailsSaga(),
    searchSubtitlesSaga(),
    searchTVSubtitlesSaga(),
    downloadSubtitleSaga(),
    convertSubtitleSaga(),
    movieCreditsSaga(),
    showCreditsSaga(),
    recommendedMoviesSaga(),
    recommendedShowSaga(),
    seasonsSaga(),
    personDetailsSaga(),
    initializeAppSaga(),
  ]);
}
