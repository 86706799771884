import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchRecommendedShowStartAction } from 'store/shows/recommended/types';
import TMDbService from '~/services/tmdbService';
import { fetchRecommendedShowFailure, fetchRecommendedShowSuccess } from 'store/shows/recommended/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* fetchRecommendedShowSaga(action: IFetchRecommendedShowStartAction): Generator {
  try {
    const recommendedMovie: any = yield call(API.getShowRecommendations, action.payload);
    yield put(fetchRecommendedShowSuccess(recommendedMovie));
  } catch (error) {
    yield put(fetchRecommendedShowFailure());
    yield put(
      Notifications.error({
        title: 'Recommended shows',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
  }
}

export default function*() {
  yield all([takeLatest(ActionTypes.FETCH_RECOMMENDED_SHOWS_START, fetchRecommendedShowSaga)]);
}
