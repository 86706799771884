import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { IShowDetails } from 'common/types/show-details';

export interface IShowDetailsState {
  showDetails: IShowDetails | null;
  fetchStatus: FetchStatus | null;
}

export enum ActionTypes {
  FETCH_SHOW_DETAILS_START = '[show] fetch show details start',
  FETCH_SHOW_DETAILS_SUCCESS = '[show] fetch show details success',
  FETCH_SHOW_DETAILS_FAILURE = '[show] fetch show details failure',
  CLEAR_SHOW_DETAILS = '[show] clear show details',
}

export interface IFetchShowDetailsStartAction extends Action {
  payload: number;
  type: ActionTypes.FETCH_SHOW_DETAILS_START;
}

export interface IFetchShowDetailsSuccessAction extends Action {
  type: ActionTypes.FETCH_SHOW_DETAILS_SUCCESS;
  payload: IShowDetails;
}

export interface IFetchShowDetailsFailureAction extends Action {
  type: ActionTypes.FETCH_SHOW_DETAILS_FAILURE;
}

export interface IClearShowDetailsAction extends Action {
  type: ActionTypes.CLEAR_SHOW_DETAILS;
}

export type IFetchShowDetailsActions =
  | IFetchShowDetailsStartAction
  | IFetchShowDetailsSuccessAction
  | IFetchShowDetailsFailureAction
  | IClearShowDetailsAction;
