import { ActionTypes, IFetchShowCreditsActions, IShowCreditsState } from 'store/shows/credits/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: IShowCreditsState = {
  showCredits: null,
  fetchStatus: null,
};

function reducer(state: IShowCreditsState = initialState, action: IFetchShowCreditsActions): IShowCreditsState {
  switch (action.type) {
    case ActionTypes.FETCH_SHOW_CREDITS_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_SHOW_CREDITS_SUCCESS:
      return {
        ...state,
        showCredits: action.payload,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_SHOW_CREDITS_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    case ActionTypes.CLEAR_SHOW_CREDITS:
      return {
        ...state,
        showCredits: null,
        fetchStatus: null,
      };
    default:
      return state;
  }
}

export default reducer;
