import { ActionTypes, IFetchSummaryActions, ISummaryState } from 'store/summary/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: ISummaryState = {
  summary: null,
  fetchStatus: null,
};

function reducer(state: ISummaryState = initialState, action: IFetchSummaryActions): ISummaryState {
  switch (action.type) {
    case ActionTypes.FETCH_SUMMARY_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    case ActionTypes.CLEAR_SUMMARY:
      return {
        ...state,
        summary: null,
        fetchStatus: null,
      };
    default:
      return state;
  }
}

export default reducer;
