import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { StateType } from 'typesafe-actions';
import { createBrowserHistory } from 'history';
import { reducer as notifications } from 'react-notification-system-redux';

import { sagaMiddleware, connectedRouterMiddleware } from 'store/middlewares';
import rootSaga from 'store/saga';
import { IS_DEV } from 'utils/env';

import searchReducer from 'store/search/reducer';
import popularContentReducer from 'store/movie/popular/reducer';
import topRatedContentReducer from 'store/movie/toprated/reducer';
import movieDetailsReducer from 'store/movie/details/reducer';
import showDetailsReducer from 'store/shows/details/reducer';
import movieImagesReducer from 'store/movie/images/reducer';
import showImagesReducer from 'store/shows/images/reducer';
import showCreditssReducer from 'store/shows/credits/reducer';
import movieCreditsReducer from 'store/movie/credits/reducer';
import recommendedMoviesReducer from 'store/movie/recommended/reducer';
import recommendedShowReducer from 'store/shows/recommended/reducer';
import seasonsReducer from 'store/shows/seasons/reducer';
import genresReducer from 'store/genres/reducer';
import favoriteMoviesReducer from 'store/favorites/reducer';
import personDetailsReducer from 'store/person/reducer';
import subtitlesReducer from 'store/subtitle/reducer';
import summaryReducer from 'store/summary/reducer';

const movieReducers = combineReducers({
  popularContent: popularContentReducer,
  movieDetails: movieDetailsReducer,
  movieCredits: movieCreditsReducer,
  recommendedMovies: recommendedMoviesReducer,
  topRatedContent : topRatedContentReducer,
  movieImages: movieImagesReducer
});

const showReducers = combineReducers({
  showDetails: showDetailsReducer,
  showCredits: showCreditssReducer,
  recommendedShow: recommendedShowReducer,
  seasons: seasonsReducer,
  showImages: showImagesReducer
});
export const history = createBrowserHistory();

const rootReducers = combineReducers({
  router: connectRouter(history),
  search: searchReducer,
  movies: movieReducers,
  shows: showReducers,
  genres: genresReducer,
  favoriteMovies: favoriteMoviesReducer,
  person: personDetailsReducer,
  notifications: notifications,
  subtitles: subtitlesReducer,
  summary: summaryReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['favoriteMovies'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers as any);

const middlewares = [sagaMiddleware, connectedRouterMiddleware(history)];

export function configureStore(initialState = {}) {
  const enhancers = [applyMiddleware(...middlewares)];
  const composedEnhancers = IS_DEV ? composeWithDevTools(...enhancers) : compose(...enhancers);
  
  const store = createStore(persistedReducer, initialState as any, composedEnhancers as any);

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store as any);

  return { store, persistor };
}

export type ApplicationState = StateType<typeof rootReducers>;
