import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchShowDetailsStartAction } from 'store/shows/details/types';
import TMDbService from '~/services/tmdbService';
import { fetchShowDetailsFailure, fetchShowDetailsSuccess } from 'store/shows/details/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* fetchShowDetailsSaga(action: IFetchShowDetailsStartAction): Generator {
  try {
    const showDetails: any = yield call(API.getShowDetails, action.payload);
    yield put(fetchShowDetailsSuccess(showDetails));
  } catch (error) {
    yield put(fetchShowDetailsFailure());
    yield put(
      Notifications.error({
        title: 'show details',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
  }
}

export default function*() {
  yield all([takeLatest(ActionTypes.FETCH_SHOW_DETAILS_START, fetchShowDetailsSaga)]);
}
