import { ActionTypes, IFetchtopRatedContentActions, ItopRatedContentState } from 'store/movie/toprated/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: ItopRatedContentState = {
  topRatedSearchPage: 1,
  topRatedMovie: null,
  fetchStatus: null,
};

function reducer(state: ItopRatedContentState = initialState, action: IFetchtopRatedContentActions): ItopRatedContentState {
  switch (action.type) {
    case ActionTypes.FETCH_TOPRATED_MOVIES_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_TOPRATED_MOVIES_SUCCESS:
      const { shouldConcat } = action.payload;

      if (shouldConcat && state.topRatedMovie) {
        return {
          ...state,
          topRatedMovie: {
            ...action.payload.movies,
            results: [...state.topRatedMovie.results, ...action.payload.movies.results],
          },
          fetchStatus: FetchStatus.SUCCESS,
        };
      }

      return {
        ...state,
        topRatedMovie: action.payload.movies,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_TOPRATED_MOVIES_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    case ActionTypes.SET_TOPRATED_SEARCH_PAGE:
      return {
        ...state,
        topRatedSearchPage: action.payload,
      };
    case ActionTypes.CLEAR_TOPRATED_SEARCH_PAGE:
      return {
        ...state,
        topRatedSearchPage: 1,
      };
    default:
      return state;
  }
}

export default reducer;
