import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { ISummary } from 'common/types/summary';

export interface ISummaryState {
  summary: ISummary | null;
  fetchStatus: FetchStatus | null;
}

export enum ActionTypes {
  FETCH_SUMMARY_START = '[summary] fetch summary start',
  FETCH_SUMMARY_SUCCESS = '[summary] fetch summary success',
  FETCH_SUMMARY_FAILURE = '[summary] fetch summary failure',
  CLEAR_SUMMARY = '[summary] clear summary',
}

export interface IFetchSummaryStartAction extends Action {
  payload: {
    text: string;
    contentType: string;
    tmdbId: number;
    seasonId: number;
    episodeId: number;
  };  type: ActionTypes.FETCH_SUMMARY_START;
}

export interface IFetchSummarySuccessAction extends Action {
  type: ActionTypes.FETCH_SUMMARY_SUCCESS;
  payload: ISummary;
}

export interface IFetchSummaryFailureAction extends Action {
  type: ActionTypes.FETCH_SUMMARY_FAILURE;
}

export interface IClearSummaryAction extends Action {
  type: ActionTypes.CLEAR_SUMMARY;
}

export type IFetchSummaryActions =
  | IFetchSummaryStartAction
  | IFetchSummarySuccessAction
  | IFetchSummaryFailureAction
  | IClearSummaryAction;
