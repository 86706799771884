import { ActionTypes, IFetchSeasonsActions, ISeasonsState } from 'store/shows/seasons/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: ISeasonsState = {
  season: null,
  fetchStatus: null,
};

function reducer(
  state: ISeasonsState = initialState,
  action: IFetchSeasonsActions
): ISeasonsState {
  switch (action.type) {
    case ActionTypes.FETCH_SEASONS_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_SEASONS_SUCCESS:
      return {
        ...state,
        season: action.payload,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_SEASONS_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    default:
      return state;
  }
}

export default reducer;
