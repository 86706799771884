import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { ISearch } from 'common/types/search';
import { IShow } from 'common/types/show';

export interface IRecommendedShowState {
  recommendedShow: ISearch<IShow> | null;
  fetchStatus: FetchStatus | null;
}

export enum ActionTypes {
  FETCH_RECOMMENDED_SHOWS_START = '[shows] fetch recommended shows start',
  FETCH_RECOMMENDED_SHOWS_SUCCESS = '[shows] fetch recommended shows success',
  FETCH_RECOMMENDED_SHOWS_FAILURE = '[shows] fetch recommended shows failure',
}

export interface IFetchRecommendedShowStartAction extends Action {
  type: ActionTypes.FETCH_RECOMMENDED_SHOWS_START;
  payload: number;
}

export interface IFetchRecommendedShowSuccessAction extends Action {
  type: ActionTypes.FETCH_RECOMMENDED_SHOWS_SUCCESS;
  payload: ISearch<IShow>;
}

export interface IFetchRecommendedShowFailureAction extends Action {
  type: ActionTypes.FETCH_RECOMMENDED_SHOWS_FAILURE;
}

export type IFetchRecommendedShowActions =
  | IFetchRecommendedShowStartAction
  | IFetchRecommendedShowSuccessAction
  | IFetchRecommendedShowFailureAction;
