import { ISearch } from 'common/types/search';
import { IMovie } from 'common/types/movie';
import { IGenre } from 'common/types/genre';
import { IShow } from 'common/types/show';
import { IMovieCredits } from 'common/types/movie-credits';
import { IMovieDetails } from 'common/types/movie-details';
import { IImages } from 'common/types/images';
import { IPersonDetails } from 'common/types/person-details';
import { IShowDetails } from 'common/types/show-details';
import { ISeason } from 'common/types/season';

export default class TMDb {
  private readonly API_BASE = 'https://api.themoviedb.org/3/';
  private readonly TMDB_API_KEY = '6407d57dcff0774f2e3f0ada54b9b0d7';
  private readonly API_LANGUAGE = 'en-US';
  public readonly API_IMAGE_PATH = 'https://image.tmdb.org/t/p/';

  private fetchJSON = async (url: string) => {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Could not fetch ${url}, received ${response.status} error`);
    }

    return response.json();
  };

  public getGenres = async (): Promise<IGenre[]> => {
    return this.fetchJSON(
      `${this.API_BASE}genre/movie/list?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`
    );
  };

  

  public getContentBySearchQuery = async (query: string, page = 1) => {
    // Endpoint for searching movies
    const moviesUrl = `${this.API_BASE}search/movie?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&query=${query}&page=${page}&include_adult=false`;
    // Endpoint for searching TV shows
    const showsUrl = `${this.API_BASE}search/tv?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&query=${query}&page=${page}&include_adult=false`;

    try {
        // Fetch movies and shows in parallel
        const [moviesResponse, showsResponse] = await Promise.all([
            this.fetchJSON(moviesUrl),
            this.fetchJSON(showsUrl),
        ]);

        // Map over movies and shows to set the mediaType explicitly
        const moviesWithMediaType = moviesResponse.results.map((movie: Partial<IMovie>) => ({
          ...movie,
          mediaType: 'movie' // Explicitly set mediaType for movies
        }));
        
        const showsWithMediaType = showsResponse.results.map((show: Partial<IShow>) => ({
          ...show,
          mediaType: 'show' // Explicitly set mediaType for shows
        }));

        // Interleave movies and shows
        const interleavedResults = this.interleaveResults(moviesWithMediaType, showsWithMediaType);

        // Prepare the final merged results object
        const mergedResults = {
            results: interleavedResults,
            page,
            total_results: moviesResponse.total_results + showsResponse.total_results,
            total_pages: Math.max(moviesResponse.total_pages, showsResponse.total_pages),
        };

        return mergedResults;
    } catch (error) {
        throw error;
    }
};

private interleaveResults = (movies: any, shows: any) => {
  const interleaved = [];
  const maxLength = Math.max(movies.length, shows.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < movies.length) {
      interleaved.push(movies[i]);
    }
    if (i < shows.length) {
      interleaved.push(shows[i]);
    }
  }

  return interleaved;
};



  public getMovieDetails = async (id: number): Promise<IMovieDetails> => {
    return this.fetchJSON(
      `${this.API_BASE}movie/${id}?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`
    );
  };

  public getMovieRecommendations = async (id: number): Promise<ISearch<IMovie>> => {
    return this.fetchJSON(
      `${this.API_BASE}movie/${id}/recommendations?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`
    );
  };

  public getLatestMovie = async (): Promise<IMovie> => {
    return this.fetchJSON(
      `${this.API_BASE}movie/latest?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&include_adult=false`
    );
  };

  public getMovieCredits = async (id: number): Promise<IMovieCredits> => {
    return this.fetchJSON(
      `${this.API_BASE}movie/${id}/credits?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`
    );
  };

  public getPersonDetails = async (id: number): Promise<IPersonDetails> => {
    return this.fetchJSON(
      `${this.API_BASE}person/${id}?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&append_to_response=movie_credits,tv_credits`
    );
  };  

  public getShowDetails = async (id: number): Promise<IShowDetails> => {
    const url = `${this.API_BASE}tv/${id}?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`;
    return this.fetchJSON(url);
  };

  public getShowCredits = async (id: number) => {
    const url = `${this.API_BASE}tv/${id}/credits?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`;
    return this.fetchJSON(url);
  };

  public getShowRecommendations = async (id: number): Promise<ISearch<IShow>> => {
    return this.fetchJSON(
      `${this.API_BASE}tv/${id}/recommendations?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`
    );
  };

  public getMovieImages = async (id: number): Promise<IImages> => {
    return this.fetchJSON(`${this.API_BASE}movie/${id}/images?api_key=${this.TMDB_API_KEY}`);
  };


  public getShowImages = async (id: number, season_id: number, episode_id: number) => {
    const url = `${this.API_BASE}tv/${id}/season/${season_id}/episode/${episode_id}/images?api_key=${this.TMDB_API_KEY}`;
    return this.fetchJSON(url);
  };

  public getpopularMovies = async (page = 1): Promise<ISearch<IMovie>> => {
    return this.fetchJSON(
      `${this.API_BASE}movie/popular?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&page=${page}`
    );
  };

  public getPopularShows = async (page = 1): Promise<ISearch<IShow>> => {
    return this.fetchJSON(
      `${this.API_BASE}tv/popular?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&page=${page}`
    );
  };

  public getTopRatedMovies = async (page = 1): Promise<ISearch<IMovie>> => {
    return this.fetchJSON(
      `${this.API_BASE}movie/top_rated?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&page=${page}`
    );
  };

  public getTopRatedShows = async (page = 1): Promise<ISearch<IShow>> => {
    return this.fetchJSON(
      `${this.API_BASE}tv/top_rated?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}&page=${page}`
    );
  };
  public getPopularContent = async (page = 1) => {
    // Fetch popular movies and shows in parallel
    const moviesPromise = this.getpopularMovies(page);
    const showsPromise = this.getPopularShows(page);
  
    try {
      const [moviesResponse, showsResponse] = await Promise.all([moviesPromise, showsPromise]);
  
      // Map over movies and shows to set the mediaType explicitly
      const moviesWithMediaType = moviesResponse.results.map(movie => ({
        ...movie,
        mediaType: 'movie'
      }));
  
      const showsWithMediaType = showsResponse.results.map(show => ({
        ...show,
        mediaType: 'show'
      }));
  
      // Interleave movies and shows
      const interleavedResults = this.interleaveResults(moviesWithMediaType, showsWithMediaType);
  
      // Filter out movies and shows with less than 1000 votes
      const filteredContentByVoteCount = interleavedResults.filter(item => item.vote_count > 100).map(item => ({
        ...item,
        // Ensuring mediaType is set, assuming it's always defined after previous operations
        mediaType: item.mediaType
      }));
  
      // Prepare the final merged results object with the filtered list
      const mergedResults = {
        results: filteredContentByVoteCount,
        page,
        // Here we can't accurately update total_results and total_pages because filtering is done after fetching.
        total_results: filteredContentByVoteCount.length,
        total_pages: Math.max(moviesResponse.total_pages, showsResponse.total_pages),
      };
  
      return mergedResults;
    } catch (error) {
      throw error;
    }
  };
  

  public getTopRatedContent = async (page = 1) => {
    // Fetch top-rated movies and shows in parallel
    const moviesPromise = this.getTopRatedMovies(page);
    const showsPromise = this.getTopRatedShows(page);
  
    try {
      const [moviesResponse, showsResponse] = await Promise.all([moviesPromise, showsPromise]);
  
      // Map over movies and shows to set the mediaType explicitly
      const moviesWithMediaType = moviesResponse.results.map(movie => ({
        ...movie,
        mediaType: 'movie'
      }));
  
      const showsWithMediaType = showsResponse.results.map(show => ({
        ...show,
        mediaType: 'show'
      }));
  
      // Interleave movies and shows
      const interleavedResults = this.interleaveResults(moviesWithMediaType, showsWithMediaType);
  
      // Filter out movies and shows with less than 1000 votes
      const filteredContentByVoteCount = interleavedResults.filter(item => item.vote_count > 2000).map(item => ({
        ...item,
        // Ensuring mediaType is set, assuming it's always defined after previous operations
        mediaType: item.mediaType
      }));
  
      // Prepare the final merged results object with the filtered list
      const mergedResults = {
        results: filteredContentByVoteCount,
        page,
        total_results: filteredContentByVoteCount.length,
        // Adjusting total_pages might not be straightforward without fetching all data,
        // so we keep it as is unless API provides a way to get this information after filtering.
        total_pages: Math.max(moviesResponse.total_pages, showsResponse.total_pages),
      };
  
      return mergedResults;
    } catch (error) {
      throw error;
    }
  };
  

  public getSeasonDetails = async (seriesId: number, seasonNumber: number): Promise<ISeason> => {
    const url = `${this.API_BASE}tv/${seriesId}/season/${seasonNumber}?api_key=${this.TMDB_API_KEY}&language=${this.API_LANGUAGE}`;
    return this.fetchJSON(url);
  };
  
}
