// src/store/subtitles/actions.ts

import { ActionTypes } from './types';
import { ISubtitle, ISubtitleFile } from 'common/types/subtitle';

export const searchSubtitlesStart = (payload: number) => ({
  type: ActionTypes.SEARCH_SUBTITLES_START,
  payload,
});

export const searchSubtitlesSuccess = (payload: ISubtitle[]) => ({
  type: ActionTypes.SEARCH_SUBTITLES_SUCCESS,
  payload,
});

export const searchSubtitlesFailure = () => ({
  type: ActionTypes.SEARCH_SUBTITLES_FAILURE,
});

export const convertSubtitlesStart = (payload: number) => ({
  type: ActionTypes.CONVERT_SUBTITLE_START,
  payload,
});

export const convertSubtitlesSuccess = (payload: string) => ({
  type: ActionTypes.CONVERT_SUBTITLE_SUCCESS,
  payload,
});

export const convertSubtitlesFailure = () => ({
  type: ActionTypes.CONVERT_SUBTITLE_FAILURE,
});

export const searchTVSubtitlesStart = (tmdbId: number, season: number, episode: number) => ({
  type: ActionTypes.SEARCH_TV_SUBTITLES_START,
  payload: {
    tmdbId,
    season,
    episode,
  },
});


export const searchTVSubtitlesSuccess = (payload: ISubtitle[]) => ({
  type: ActionTypes.SEARCH_TV_SUBTITLES_SUCCESS,
  payload,
});

export const searchTVSubtitlesFailure = () => ({
  type: ActionTypes.SEARCH_TV_SUBTITLES_FAILURE,
});

export const downloadSubtitleStart = (payload: number) => ({
  type: ActionTypes.DOWNLOAD_SUBTITLE_START,
  payload,
});

export const downloadSubtitleSuccess = (payload: ISubtitleFile) => ({
  type: ActionTypes.DOWNLOAD_SUBTITLE_SUCCESS,
  payload,
});

export const downloadSubtitleFailure = () => ({
  type: ActionTypes.DOWNLOAD_SUBTITLE_FAILURE,
});

export const clearSubtitles = () => ({
  type: ActionTypes.CLEAR_SUBTITLES,
});
