import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchSeasonsStartAction } from 'store/shows/seasons/types';
import TMDbService from '~/services/tmdbService';
import { fetchSeasonsFailure, fetchSeasonsSuccess } from 'store/shows/seasons/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* fetchSeasonSaga(action: IFetchSeasonsStartAction): Generator {
  try {
    // Ensure you're passing parameters correctly
    const season: any = yield call([API, API.getSeasonDetails], action.payload.seriesId, action.payload.seasonNumber);
    // Proceed with dispatching success action
    yield put(fetchSeasonsSuccess(season));
  } catch (error) {
    // Handle failure
    yield put(fetchSeasonsFailure());
    // Error notification
    yield put(
      Notifications.error({
        title: 'Seasons',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
  }
}


export default function*() {
  yield all([takeLatest(ActionTypes.FETCH_SEASONS_START, fetchSeasonSaga)]);
}
