import { useQuery } from 'react-query';
import { ISubtitle, ISubtitleFile } from 'common/types/subtitle';

const API_BASE = '/api'; // Assuming your Express server's routes are prefixed with '/api'

// Helper function to fetch JSON from your backend
async function fetchJSON(url: string, options?: RequestInit): Promise<any> {
  const response = await fetch(`${API_BASE}${url}`, options);
  if (!response.ok) {
    throw new Error(`Could not fetch ${url}, received ${response.status} error`);
  }
  return response.json();
}

// Custom hook to search subtitles by TMDB ID
export function useSearchSubtitlesByTMDB(tmdbId: number) {
  return useQuery<ISubtitle | null, Error>(
    ['searchSubtitlesByTMDB', tmdbId], 
    async () => {
      const response = await fetchJSON(`/search-subtitles?tmdb_id=${tmdbId}`);
      // Assume your backend returns the subtitles sorted by download_count or a relevant metric
      return response.length > 0 ? response[0] : null;
    }, 
    {
      retry: false
    }
  );
}

// Custom hook to search TV subtitles by TMDB ID, season, and episode
export function useSearchTVSubtitlesByTMDB(tmdbId: number, season: number, episode: number) {
  return useQuery<ISubtitle | null, Error>(
    ['searchTVSubtitlesByTMDB', tmdbId, season, episode],
    async () => {
      const response = await fetchJSON(`/search-tv-subtitles?tmdb_id=${tmdbId}&season_number=${season}&episode_number=${episode}`);
      return response.length > 0 ? response[0] : null;
    },
    {
      retry: false
    }
  );
}
// Function for downloading and converting subtitles
export async function downloadAndConvertSubtitle(fileId: number): Promise<string> {
  const response = await fetchJSON('/download-and-convert-subtitle', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fileId })
  });
  // Assuming the backend returns the plain text directly
  return response.plainText;
}
