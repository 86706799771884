import { ActionTypes } from 'store/movie/toprated/types';
import { ISearch } from 'common/types/search';
import { IMovie } from 'common/types/movie';

export function fetchtopRatedContentStart() {
  return {
    type: ActionTypes.FETCH_TOPRATED_MOVIES_START,
  };
}

export function fetchtopRatedContentSuccess(payload: { movies: ISearch<IMovie>; shouldConcat?: boolean }) {
  return {
    type: ActionTypes.FETCH_TOPRATED_MOVIES_SUCCESS,
    payload,
  };
}

export function fetchtopRatedContentFailure() {
  return {
    type: ActionTypes.FETCH_TOPRATED_MOVIES_FAILURE,
  };
}

export function cleartopRatedSearchPage() {
  return {
    type: ActionTypes.CLEAR_TOPRATED_SEARCH_PAGE,
  };
}

export function settopRatedSearchPage(payload: number) {
  return {
    type: ActionTypes.SET_TOPRATED_SEARCH_PAGE,
    payload,
  };
}
