import { ActionTypes, IFetchShowDetailsActions, IShowDetailsState } from 'store/shows/details/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: IShowDetailsState = {
  showDetails: null,
  fetchStatus: null,
};

function reducer(state: IShowDetailsState = initialState, action: IFetchShowDetailsActions): IShowDetailsState {
  switch (action.type) {
    case ActionTypes.FETCH_SHOW_DETAILS_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_SHOW_DETAILS_SUCCESS:
      return {
        ...state,
        showDetails: action.payload,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_SHOW_DETAILS_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    case ActionTypes.CLEAR_SHOW_DETAILS:
      return {
        ...state,
        showDetails: null,
        fetchStatus: null,
      };
    default:
      return state;
  }
}

export default reducer;
