import React, { useEffect, useState, ComponentType } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Define the types for the props that ProtectedRoute will receive
interface ProtectedRouteProps extends RouteProps {
  component: ComponentType<RouteProps>; // Specifies that the component prop is a React component that accepts RouteProps
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default ProtectedRoute;
