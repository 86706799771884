import React, { FunctionComponent } from 'react';
import './TermsofService.css'; // Ensure the CSS file is imported

const TermsOfService: FunctionComponent = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <p>Last Updated: March 8, 2024</p>
      <h2>1. Introduction and Overview</h2>
      <p>Welcome to Recapflix, a unique service provided by Subhash Ventures, Inc ("Subhash Ventures"). Recapflix offers you an innovative way to discover and engage with movie and TV show summaries. By using our service, you agree to these Terms of Service ("Terms"), which govern your access to and use of our website, recapflix.com ("Service"). Please read these Terms carefully before using the Service.</p>
      <h2>2. Eligibility</h2>
      <p>The Service is available to individuals who are at least eighteen (18) years old, possess a valid email address, and have a valid payment method such as a credit card. We reserve the right to refuse service, terminate accounts, or cancel orders at our sole discretion.</p>
      <h2>3. Service Description</h2>
      <p>Recapflix allows users to search for movies and TV shows and receive AI-generated summaries ("Recaps"). Upon first use, users can sign up to receive three free movie Recaps. Further access requires the purchase of credits through payment.</p>
      <h2>4. User Accounts</h2>
      <p>To access certain features of the Service, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
      <h2>5. Purchases and Payments</h2>
      <p>Recapflix operates on a credit purchase system for accessing Recaps beyond the initial free summaries. All transactions are processed through secure payment gateways. Prices for credits and other charges are subject to change without notice.</p>
      <h2>6. Intellectual Property</h2>
      <p>The content provided through Recapflix, including all information, text, and summaries, is the property of Subhash Ventures or its licensors and is protected by copyright and other intellectual property laws.</p>
      <h2>7. User Conduct</h2>
      <p>You agree not to use the Service for any unlawful purpose or in any way that interrupts, damages, or impairs the service. You agree not to use the Service to harass, abuse, or harm others.</p>
      <h2>8. Disclaimers and Limitation of Liability</h2>
      <p>Recapflix is provided "AS IS" without warranties of any kind. Subhash Ventures will not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Service.</p>
      <h2>9. Changes to Terms</h2>
      <p>Subhash Ventures reserves the right to modify these Terms at any time. Your continued use of the Service following such changes will indicate your acceptance of the new Terms.</p>
      <h2>10. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of the state of Delaware and the United States, without regard to its conflict of law provisions.</p>
      <h2>11. Contact Information</h2>
      <p>For any questions regarding these Terms, please contact us at support@recapflix.com.</p>
    </div>
  );
};

export default TermsOfService;
