import { ActionTypes, IFetchRecommendedShowActions, IRecommendedShowState } from 'store/shows/recommended/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: IRecommendedShowState = {
  recommendedShow: null,
  fetchStatus: null,
};

function reducer(
  state: IRecommendedShowState = initialState,
  action: IFetchRecommendedShowActions
): IRecommendedShowState {
  switch (action.type) {
    case ActionTypes.FETCH_RECOMMENDED_SHOWS_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_RECOMMENDED_SHOWS_SUCCESS:
      return {
        ...state,
        recommendedShow: action.payload,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_RECOMMENDED_SHOWS_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    default:
      return state;
  }
}

export default reducer;
