import { ActionTypes, IFetchShowImagesActions, IShowImagesState } from 'store/shows/images/types';
import { FetchStatus } from 'common/types/fetch-status';

const initialState: IShowImagesState = {
  showImages: null,
  fetchStatus: null,
};

function reducer(state: IShowImagesState = initialState, action: IFetchShowImagesActions): IShowImagesState {
  switch (action.type) {
    case ActionTypes.FETCH_SHOW_IMAGES_START:
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
      };
    case ActionTypes.FETCH_SHOW_IMAGES_SUCCESS:
      return {
        ...state,
        showImages: action.payload,
        fetchStatus: FetchStatus.SUCCESS,
      };
    case ActionTypes.FETCH_SHOW_IMAGES_FAILURE:
      return {
        ...state,
        fetchStatus: FetchStatus.FAILURE,
      };
    case ActionTypes.CLEAR_SHOW_IMAGES:
      return {
        ...state,
        showImages: null,
        fetchStatus: null,
      };
    default:
      return state;
  }
}

export default reducer;