import { all, call, debounce, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchtopRatedContentStartAction, ISettopRatedSearchPage } from 'store/movie/toprated/types';
import TMDbService from '~/services/tmdbService';
import { fetchtopRatedContentFailure, fetchtopRatedContentSuccess } from 'store/movie/toprated/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* savetopRatedContent(page = 1, shouldConcat = false): Generator {
  try {
    const topRatedContent: any = yield call(API.getTopRatedContent, page);
    yield put(
      fetchtopRatedContentSuccess({
        movies: topRatedContent,
        shouldConcat,
      })
    );
  } catch (error) {
    yield put(fetchtopRatedContentFailure());
    yield put(
      Notifications.error({
        title: 'topRated movies',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
  }
}

function* fetchtopRatedContentSaga(action: IFetchtopRatedContentStartAction) {
  yield savetopRatedContent(1, false);
}

function* fetchtopRatedContentWithPageSaga(action: ISettopRatedSearchPage) {
  const page = action.payload;

  yield savetopRatedContent(page, true);
}

export default function*() {
  yield all([
    debounce(150, ActionTypes.SET_TOPRATED_SEARCH_PAGE, fetchtopRatedContentWithPageSaga),
    takeLatest(ActionTypes.FETCH_TOPRATED_MOVIES_START, fetchtopRatedContentSaga),
  ]);
}
