// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import getFirestore

const firebaseConfig = {
    apiKey: "AIzaSyDbV-sQStOTjpdIvZN1TTCaJDadDHbLzZo",
    authDomain: "recapflix-18de1.firebaseapp.com",
    projectId: "recapflix-18de1",
    storageBucket: "recapflix-18de1.appspot.com",
    messagingSenderId: "644351503030",
    appId: "1:644351503030:web:5af2bbacdab1f7bca71417",
    measurementId: "G-HXMEQ4R8TM"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the auth service
const auth = getAuth(app);

export { auth };
