import React, { FunctionComponent, lazy, Suspense, useEffect } from 'react';
import { Route, Redirect, Switch, useLocation, BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { configureStore, history } from 'store/store';
import '@/assets/sass/main.sass';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConnectedRouter } from 'connected-react-router';
import HomeHandler from './HomeHandler'; // Import the HomeHandler component
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ProtectedRoute from './ProtectedRoute';// Components import
import ContentProtectedRoute from './ContentProtectedRoute';// Components import

import { useParams } from 'react-router-dom';

import TopBar from 'components/partials/AppTopNav';
import AppNav from 'components/partials/AppNav';
import AppSearch from 'components/partials/AppSearch';
import ErrorBoundary from 'components/partials/AppErrorBoundary';
import AppNotifications from 'components/partials/AppNotifications/AppNotifications';
import AppScrollRestorator from 'components/partials/AppScrollRestorator/AppScrollResorator';

// Pages and other components
const Movie = lazy(() => import('pages/Movie'));
const MovieRecap = lazy(() => import('pages/MovieRecap'));
const ShowRecap = lazy(() => import('pages/ShowRecap'));
const Show = lazy(() => import('pages/Show')); // Import Show component lazily
const Error404 = lazy(() => import('pages/Error404'));
const Search = lazy(() => import('pages/Search'));
const PopularContent = lazy(() => import('~/pages/PopularContent'));
import { MyAccount, PurchaseCreditsPage } from '~/pages/MyAccount';
import TopRatedContent from '~/pages/TopRatedContent';
import PrivacyPolicy from '~/pages/PrivacyPolicy';
import TermsofService from '~/pages/TermsofService';
const savedMedia = lazy(() => import('~/pages/SavedMedia'));
const Person = lazy(() => import('pages/Person'));
const SignupComponent = lazy(() => import('components/ui/SignUp/SignupComponent'));
const LoginComponent = lazy(() => import('components/ui/SignUp/LoginComponent'));
const PasswordResetPage = lazy(() => import('components/ui/SignUp/PasswordResetPage'));

// Other lazy imports...
// Define an interface for your expected route params
interface RouteParams {
  id: string;
  slug?: string; // Optional parameter
}


const useAdjustVH = () => {
  useEffect(() => {
    const adjustVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', adjustVH);
    // Set the initial value
    adjustVH();

    return () => window.removeEventListener('resize', adjustVH);
  }, []);
};

const { store, persistor } = configureStore();
const queryClient = new QueryClient();

const MainContent: FunctionComponent = () => {
  const location = useLocation();
  const isStandalonePage = location.pathname === '/signup' || location.pathname === '/login' || location.pathname === '/reset-password'; // Add '/reset-password' here
  const params = useParams<RouteParams>();

  return (
    <>
      {!isStandalonePage && (
        <>
          <TopBar />
          <AppNav />
          <AppSearch />
        </>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
        <Route path='/' exact component={HomeHandler} /> {/* Example public home route */}
          {/* Other routes */}
                  <Route path="/movie/:id/:slug?" exact component={Movie} />
                  <Route path='/show/:id/:slug?' exact component={Show} />
                  <Route path='/person/:id/:slug?' exact component={Person} />
                  <Route path='/search' exact render={() => <Search />} />
                  <Route path='/popular' exact render={() => <PopularContent />} />
                  <Route path='/toprated' exact render={() => <TopRatedContent />} />
                  <Route path='/privacy-policy' exact render={() => <PrivacyPolicy />} />
                  <Route path='/terms-of-service' exact render={() => <TermsofService />} />
                  <ProtectedRoute path='/myaccount' exact component={MyAccount} />
                  <ProtectedRoute path='/purchase' exact component={PurchaseCreditsPage} />
                  <ProtectedRoute path='/saved' exact component={savedMedia} />
     {/* Use ProtectedRoute for recap routes */}
     <Route path='/recapmovie/:id' exact>
  <ContentProtectedRoute recapType="movie">
    <MovieRecap />
  </ContentProtectedRoute>
</Route>
<Route path='/recapshow/:id/season/:seasonNumber/episode/:episodeNumber'  exact>
  <ContentProtectedRoute recapType="show">
    <ShowRecap />
  </ContentProtectedRoute>
</Route>
          {/* Other routes, including signup, login, etc. */}
                  <Route path='/reset-password' exact component={PasswordResetPage} /> {/* Add this line */}
          <Route path='/signup' exact component={SignupComponent} />
          <Route path='/login' exact component={LoginComponent} />
          {/* Fallback route */}
          <Route component={Error404} />
        </Switch>
      </Suspense>
    </>
  );
};

const App: FunctionComponent = () => {
  useAdjustVH();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <AppNotifications />
            <ConnectedRouter history={history}>
              <Route component={AppScrollRestorator} />
              <MainContent />
            </ConnectedRouter>
          </ErrorBoundary>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
