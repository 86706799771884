import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const SendResetEmailButton = () => {
    const [sending, setSending] = useState(false);
    const [emailSent, setEmailSent] = useState(false); // New state variable
    const [message, setMessage] = useState('');
    const auth = getAuth();

    const handleSendResetEmail = () => {
        setSending(true);
        const email = auth.currentUser?.email;
        if (email) {
            sendPasswordResetEmail(auth, email).then(() => {
                setMessage('Check your email to reset your password.');
                setEmailSent(true); // Update state when email is sent
                setSending(false);
            }).catch((error) => {
                setMessage('Failed to send password reset email. Please try again later.');
                setSending(false);
            });
        }
    };

    return (
        <>
            {/* Render the button only if email has not been sent */}
            {!emailSent && (
                <button 
                    onClick={handleSendResetEmail} 
                    disabled={sending} 
                    className="reset-email-button"> {/* Use the CSS class */}
                    {sending ? 'Sending...' : 'Send Password Reset Email'}
                </button>
            )}
            {message && <p>{message}</p>}
        </>
    );
};

export default SendResetEmailButton;
