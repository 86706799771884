import React, { FunctionComponent } from 'react';
import { cn } from '@bem-react/classname';

import AppSocials from 'components/ui/AppSocials';

import './AppFooter.sass';

const b = cn('PageFooter');

const AppFooter: FunctionComponent = () => {
  return (
    <footer className={b()}>
      <div className={b('Text')}>
        <p className='smallfooterfont'>
          Made with love in California. <br />
          © Subhash Ventures 2024.{' '}
          Contact: support@recapflix.com.{' '} <br></br>
          <div className={b('LinksWrapper')}>
            <a href="/privacy-policy" className={b('Link')}>Privacy Policy</a>{' '}
            <a href="/terms-of-service" className={b('Link')}>Terms of Service</a>
          </div>
        </p>
      </div>
    </footer>
  );
};

export default AppFooter;
