import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'components/ui/SignUp/firebaseconfig'; // Adjust path as necessary
import AppSpin from 'components/ui/AppSpin/AppSpin'; // Loading component

const HomeHandler = () => {
  const [user, loading] = useAuthState(auth);
  const [isUserChecked, setIsUserChecked] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsUserChecked(true);
    }
  }, [loading]);

  if (!isUserChecked || loading) {
    return <AppSpin minHeight={563} />; // Adjust the minHeight value as needed
  }

  if (user) {
    const HomeLoggedIn = React.lazy(() => import('pages/HomeLoggedIn'));
    return <React.Suspense fallback={<AppSpin minHeight={563} />}><HomeLoggedIn /></React.Suspense>;
  } else {
    const Home = React.lazy(() => import('pages/Home'));
    return <React.Suspense fallback={<AppSpin minHeight={563} />}><Home /></React.Suspense>;
  }
};

export default HomeHandler;
