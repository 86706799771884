import { ActionTypes } from 'store/shows/images/types';
import { IImages } from 'common/types/images';

export function fetchShowImagesStart(seriesId: number, seasonNumber: number, episodeNumber: number) {
  return {
    type: ActionTypes.FETCH_SHOW_IMAGES_START,
    payload: { seriesId, seasonNumber, episodeNumber},
  };
}

export function fetchShowImagesSuccess(payload: IImages) {
  return {
    type: ActionTypes.FETCH_SHOW_IMAGES_SUCCESS,
    payload,
  };
}

export function fetchShowImagesFailure() {
  return {
    type: ActionTypes.FETCH_SHOW_IMAGES_FAILURE,
  };
}

export function clearShowImages() {
  return {
    type: ActionTypes.CLEAR_SHOW_IMAGES,
  };
}