import React, { FunctionComponent } from 'react';
import { cn } from '@bem-react/classname';
import { Link } from 'react-router-dom';
import AppLazyImage from 'components/ui/AppLazyImage';
import './AppCard.sass';
import { buildImagePath } from 'utils/buildImagePath';
import { PosterSizes } from 'common/types/images-sizes';

const b = cn('Card');

interface IProps {
  title: string;
  poster_path: string;
  id: number;
  vote_average: number;
  shouldOpenInNewTab?: boolean;
  mediaType: 'movie' | 'show';
  path?: string;
  link?: string;
}

const AppCard: FunctionComponent<IProps> = ({ title, poster_path, id, vote_average, shouldOpenInNewTab, mediaType, path, link }) => {
  const slugify = (title: string) => {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  };

  const cardContent = (
    <>
      <figure className={b('Figure')}>
        <picture className={b('Picture')}>
          <AppLazyImage
            image={buildImagePath(poster_path, PosterSizes.w342)}
            className={b('Img')}
            width={240}
            height={360}
            alt={title}
          />
        </picture>
      </figure>
      <h2 className={b('Name')}>{title}</h2>
    </>
  );

  return (
    <article className={b()}>
      <Link 
        to={path || link || `/${mediaType}/${id}/${slugify(title)}`} 
        className={b('Link')} 
        aria-label={`Open ${title}`}
      >
        {cardContent}
      </Link>
    </article>
  );
  
};

export default AppCard;
