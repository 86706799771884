import { Action } from 'redux';
import { ISubtitle, ISubtitleFile } from 'common/types/subtitle';

export enum ActionTypes {
  SEARCH_SUBTITLES_START = '[subtitles] search subtitles start',
  SEARCH_SUBTITLES_SUCCESS = '[subtitles] search subtitles success',
  SEARCH_SUBTITLES_FAILURE = '[subtitles] search subtitles failure',
  SEARCH_TV_SUBTITLES_START = '[tvsubtitles] search subtitles start',
  SEARCH_TV_SUBTITLES_SUCCESS = '[tvsubtitles] search subtitles success',
  SEARCH_TV_SUBTITLES_FAILURE = '[tvsubtitles] search subtitles failure',
  DOWNLOAD_SUBTITLE_START = '[subtitles] download subtitle start',
  DOWNLOAD_SUBTITLE_SUCCESS = '[subtitles] download subtitle success',
  DOWNLOAD_SUBTITLE_FAILURE = '[subtitles] download subtitle failure',
  CONVERT_SUBTITLE_START = '[subtitles] convert subtitle start',
  CONVERT_SUBTITLE_SUCCESS = '[subtitles] convert subtitle success',
  CONVERT_SUBTITLE_FAILURE = '[subtitles] convert subtitle failure',
  CLEAR_SUBTITLES = '[subtitles] clear subtitles',
}

export interface ISubtitlesState {
  searchResults: ISubtitle[] | null;
  downloadResults: ISubtitleFile | null;
  downloadStatus: 'pending' | 'success' | 'failure' | null;
  searchStatus: 'pending' | 'success' | 'failure' | null;
  convertedText: string;
  convertStatus: 'pending' | 'success' | 'failure' | null;
}

// Action interfaces
export interface SearchSubtitlesStartAction extends Action<ActionTypes.SEARCH_SUBTITLES_START> {
  payload: number; // Assuming the payload is the TMDB ID for the search
}

export interface SearchSubtitlesSuccessAction extends Action<ActionTypes.SEARCH_SUBTITLES_SUCCESS> {
  payload: ISubtitle[];
}

export interface SearchSubtitlesFailureAction extends Action<ActionTypes.SEARCH_SUBTITLES_FAILURE> {}

export interface ConvertSubtitlesStartAction extends Action<ActionTypes.CONVERT_SUBTITLE_START> {
  payload: number; // Assuming the payload is the TMDB ID for the search
}

export interface ConvertSubtitlesSuccessAction extends Action<ActionTypes.CONVERT_SUBTITLE_SUCCESS> {
  payload: string;
}

export interface ConvertSubtitlesFailureAction extends Action<ActionTypes.CONVERT_SUBTITLE_FAILURE> {}

// TV Action interfaces
export interface SearchTVSubtitlesStartAction extends Action<ActionTypes.SEARCH_TV_SUBTITLES_START> {
  payload: {
    tmdbId: number;
    season: number;
    episode: number;
  };
}


export interface SearchTVSubtitlesSuccessAction extends Action<ActionTypes.SEARCH_TV_SUBTITLES_SUCCESS> {
  payload: ISubtitle[];
}

export interface SearchTVSubtitlesFailureAction extends Action<ActionTypes.SEARCH_TV_SUBTITLES_FAILURE> {}

export interface DownloadSubtitleStartAction extends Action<ActionTypes.DOWNLOAD_SUBTITLE_START> {
  payload: number; // Adjust according to what your action actually needs
}

export interface DownloadSubtitleSuccessAction extends Action<ActionTypes.DOWNLOAD_SUBTITLE_SUCCESS> {
  payload: ISubtitleFile;
}

export interface DownloadSubtitleFailureAction extends Action<ActionTypes.DOWNLOAD_SUBTITLE_FAILURE> {}

export interface ClearSubtitlesAction extends Action<ActionTypes.CLEAR_SUBTITLES> {}

// Combining all subtitle action types into a single type
export type SubtitleActions =
  | SearchSubtitlesStartAction
  | SearchSubtitlesSuccessAction
  | SearchSubtitlesFailureAction
  | SearchTVSubtitlesStartAction
  | SearchTVSubtitlesSuccessAction
  | SearchTVSubtitlesFailureAction
  | DownloadSubtitleStartAction
  | DownloadSubtitleSuccessAction
  | DownloadSubtitleFailureAction
  | ClearSubtitlesAction
  | ConvertSubtitlesStartAction
  | ConvertSubtitlesSuccessAction
  | ConvertSubtitlesFailureAction;
