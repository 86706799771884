import { ActionTypes } from 'store/shows/recommended/types';
import { ISearch } from 'common/types/search';
import { IShow } from 'common/types/show';

export function fetchRecommendedShowStart(payload: number) {
  return {
    type: ActionTypes.FETCH_RECOMMENDED_SHOWS_START,
    payload,
  };
}

export function fetchRecommendedShowSuccess(payload: ISearch<IShow>) {
  return {
    type: ActionTypes.FETCH_RECOMMENDED_SHOWS_SUCCESS,
    payload,
  };
}

export function fetchRecommendedShowFailure() {
  return {
    type: ActionTypes.FETCH_RECOMMENDED_SHOWS_FAILURE,
  };
}
