import { useState, useEffect, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'components/ui/SignUp/firebaseconfig';
import TMDbService from '~/services/tmdbService';
import { IMovieDetails } from 'common/types/movie-details';
import { IShowDetails } from 'common/types/show-details';
const SERVER_URL = 'https://recapflix-e82c0158527d.herokuapp.com';

// Assuming IMovieDetails and IShowDetails have a common structure or can be discriminated
interface Cache {
  [key: string]: Promise<IMovieDetails | IShowDetails>;
}

const useSavedContent = (refetchTrigger: any) => {
  const [savedContent, setSavedContent] = useState<any[]>([]);
  const [user, loading] = useAuthState(auth);
  const API = new TMDbService();
  const cacheRef = useRef<Cache>({});

  useEffect(() => {
    const fetchSavedContent = async () => {
      const cache = cacheRef.current; // This is persistent now
      if (user && !loading) {
        const idToken = await user.getIdToken();
        const response = await fetch(`${SERVER_URL}/api/user-recaps`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
        });

        if (response.ok) {
          const data = await response.json();
          const detailedContentPromises = data.map(async (item: any) => {
            const isMovie = item.season === undefined || item.season === -1;
            const cacheKey = item.tmdb_id.toString();
            let details;

            if (!cacheRef.current[cacheKey]) {
              // Fetch and cache details if not already cached
              const detailsPromise = isMovie
              ? API.getMovieDetails(item.tmdb_id)
              : API.getShowDetails(item.tmdb_id);
            cacheRef.current[cacheKey] = detailsPromise;
            details = await detailsPromise; // Await the actual data
            } else {
              details = await cacheRef.current[cacheKey];
            }

            const showDetails = details as IShowDetails;

            let title = item.title;
            let path;
            if (!isMovie && item.season !== undefined && item.episode !== undefined) {
              title = `${showDetails.name} - S${item.season}E${item.episode}`;
              path = `/recapshow/${details.id}/season/${item.season}/episode/${item.episode}`;
            } else if (isMovie) {
              path = `/recapmovie/${details.id}`;
            }

            return {
              ...item,
              title,
              path,
              mediaType: isMovie ? 'movie' : 'show',
              details,
            };
          });

          const detailedContent = await Promise.all(detailedContentPromises);
          setSavedContent(detailedContent);
        }
      }
    };

    fetchSavedContent();
  }, [user, loading, refetchTrigger]);

  return savedContent;
};

export default useSavedContent;
