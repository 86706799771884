import React, { FunctionComponent, useCallback, useEffect, useState} from 'react';

import AppContent from 'components/ui/AppContent';
import AppFooter from 'components/partials/AppFooter';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentPagetopRatedContent,
  gettopRatedContent,
  gettopRatedContentFetchStatus,
  hasMorePagesWithtopRatedContent,
} from 'store/movie/toprated/selectors';
import { FetchStatus } from 'common/types/fetch-status';
import { IUnifiedMediaItem } from 'common/types/media'; // Ensure this import path is correct
import { b } from 'components/ui/AppContent/AppContent';
import { AppCardInfinityList } from 'components/ui/AppCardList';
import { fetchtopRatedContentStart, settopRatedSearchPage } from 'store/movie/toprated/actions';
import useSavedContent from '../SavedMedia/SavedMediaComponent'
import { Helmet } from 'react-helmet';

const TopRatedContent: FunctionComponent = () => {
  const dispatch = useDispatch();
  const topRatedContent = useSelector(gettopRatedContent);
  const hasMoreElements = useSelector(hasMorePagesWithtopRatedContent);
  const currentPage = useSelector(getCurrentPagetopRatedContent);
  const requestStatus = useSelector(gettopRatedContentFetchStatus);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const savedContent = useSavedContent(refetchTrigger);
    const findSavedContentPath = (id : number) => {
    const matchedContent = savedContent.find(content => content.tmdb_id === id);
    return matchedContent ? matchedContent.path : null;
  };
  useEffect(() => {
    dispatch(fetchtopRatedContentStart());
  }, [dispatch]);

  useEffect(() => {
    document.title = 'Recapflix | Top rated content 🔥';
  }, []);

  const loadMoreCards = useCallback(() => {
    if (requestStatus !== FetchStatus.PENDING) {
      const nextPage = currentPage + 1;
      dispatch(settopRatedSearchPage(nextPage));
    }
  }, [dispatch, requestStatus, currentPage]);

  const filteredContentByVoteCount: IUnifiedMediaItem[] = topRatedContent
  ? topRatedContent.results
      .filter(item => item.vote_count > 10)
      .map(item => ({
        ...item,
        mediaType: item.mediaType || 'movie' // Ensuring mediaType is never undefined
      }))
  : [];


  const itemsWithCorrectMediaType: IUnifiedMediaItem[] = filteredContentByVoteCount.map(item => ({
    ...item,
    mediaType: item.mediaType || 'movie' // Default to 'movie' if mediaType is not set
  }));
   // Modify movies before passing them to AppCardInfinityList
   const modifiedMovies = topRatedContent?.results.map(movie => {
    const customPath = findSavedContentPath(movie.id);
    return {
      ...movie,
      path: customPath || ``, // Add the custom path to each movie object, if found
    };
  }) || [];
  return (
    <>
     <Helmet>
    <title>Recapflix | Top Rated Movies & Shows 🔥</title>
    <meta name="description" content="Explore the highest-rated movies and shows on Recapflix. Dive into the best of entertainment curated by viewer ratings." />
    <meta property="og:title" content="Recapflix | Top Rated Movies & Shows" />
    <meta property="og:description" content="Discover top-rated movies and shows on Recapflix. Find out what’s trending in the world of entertainment based on viewer ratings." />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`${window.location.href}`} />
    <meta property="og:image:alt" content="Top rated movies and shows on Recapflix" />
    <meta name="twitter:title" content="Recapflix | Top Rated Movies & Shows" />
    <meta name="twitter:description" content="Check out the highest-rated movies and shows on Recapflix and find your next favorite." />
    <meta name="twitter:image:alt" content="Top rated movies and shows on Recapflix" />
    <script type="application/ld+json">
    {JSON.stringify({
      "@context": "http://schema.org",
      "@type": "ItemList",
      "name": "Top Rated Movies and Shows",
      "description": "Explore the highest-rated movies and shows on Recapflix, curated by viewer ratings based on top user ratings.",
      "url": window.location.href,
      "about": {
        "@type": "ItemList",
        "itemListElement": topRatedContent?.results.map((item, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "name": item.title
        }))
      }
    }, null, 2)}
  </script>  
  </Helmet>
      <AppContent className={b({ withPaddings: true })}>
        {itemsWithCorrectMediaType.length > 0 && (
          <AppCardInfinityList
            currentPage={currentPage}
            hasMoreElements={hasMoreElements}
            title='Top rated movies and shows'
            movies={modifiedMovies as IUnifiedMediaItem[]}
            onLoadHandler={loadMoreCards}
          />
        )}
      </AppContent>
      <AppFooter />
    </>
  );
};

export default TopRatedContent;
