import React, { FunctionComponent, useState } from 'react';
import { cn } from '@bem-react/classname';

import AppCard, { AppImageCard } from 'components/ui/AppCard';
import { IImage } from 'common/types/images';
import { IUnifiedMediaItem } from 'common/types/media';


import './AppCardList.sass';
import AppLightbox from 'components/ui/AppLightbox/AppLightbox';

const b = cn('CardList');

interface IImageList extends IProps {
  images: IImage[];
  videos?: never;
  movies?: never;
}

interface IMovieList extends IProps {
  movies: IUnifiedMediaItem[];
  videos?: never;
  images?: never;
}

interface IProps {
  title: string;
}

type ConditionalListProps =  IImageList | IMovieList;

const AppCardList: FunctionComponent<ConditionalListProps> = ({ title, videos, images, movies }) => {
  const [isLightboxVisible, setLightboxVisibility] = useState(false);
  const [setVideoModalVisibility] = useState(false);

  const [currentPhotoIndex, setIndex] = useState(0);
  const [setVideoKey] = useState('');

  const handleImageCardClick = (path: IImage['file_path']) => {
    setLightboxVisibility(true);
    const newIndex = images?.findIndex((image) => image.file_path === path) || 0;
    setIndex(newIndex);
  };

  return (
    <div className={b()}>
      <div className={b('Heading')}>
        <h2 className={b('Title')}>{title}</h2>
        <strong className={b('Counter')}>{images?.length || movies?.length} items</strong>
      </div>
      <div className={b('Content')}>
        <ul className={b('List', { '3items': Boolean(videos), '6items': Boolean(images) || Boolean(movies) })}>
      

          {images &&
            images.map((image) => {
              return (
                <li className={b('Item')} key={image.file_path}>
                  <AppImageCard file_path={image.file_path} onClick={(path) => handleImageCardClick(path)} />
                </li>
              );
            })}
          {images && isLightboxVisible && (
            <AppLightbox images={images} startIndex={currentPhotoIndex} onClose={() => setLightboxVisibility(false)} />
          )}

          {movies &&
            movies.map((movie) => {
              return (
                <li className={b('Item')} key={movie.id}>
                  <AppCard
                    id={movie.id}
                    poster_path={movie.poster_path}
                    title={movie.title || movie.name || 'Untitled'}
                    vote_average={movie.vote_average}
                    mediaType={movie.mediaType}
                    path={movie.path} // Pass the path property here
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default AppCardList;
