import { ActionTypes } from 'store/shows/seasons/types';
import { ISeason } from 'common/types/season';

export function fetchSeasonsStart(seriesId: number, seasonNumber: number) {
  return {
    type: ActionTypes.FETCH_SEASONS_START,
    payload: { seriesId, seasonNumber },
  };
}

export function fetchSeasonsSuccess(payload: ISeason) {
  return {
    type: ActionTypes.FETCH_SEASONS_SUCCESS,
    payload,
   };
}

export function fetchSeasonsFailure() {
  return {
    type: ActionTypes.FETCH_SEASONS_FAILURE,
  };
}
