import React, { FunctionComponent } from 'react';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { Link, useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'components/ui/SignUp/firebaseconfig';

import AppIcon from 'components/ui/AppIcon';
import recapflix from 'src/assets/img/recapflix.png';
import AppSocials from 'components/ui/AppSocials';

import './TopBar.sass';

const b = cn('TopBar');

interface IProps {
  className?: string;
}

const TopBar: FunctionComponent<IProps> = ({ className }) => {
  const [user] = useAuthState(auth);
  const history = useHistory();

  const handleSignOut = async () => {
    await auth.signOut();
    history.push('/');
  };

  return (
    <div className={classnames(b(), className)}>
      <div className={b('Logo')}>
        <AppSocials />
      </div>
      <div className={b('Links')}>
        {user ? (
          <>
            {/* Show My Account and Sign Out if user is logged in */}
            <div className={b('MyAccount')}>
              <Link to="/myaccount" className={b('LoginButton')}>My Account</Link>
            </div>
            <div className={b('LogIn')}>
              <button onClick={handleSignOut} className={b('LoginButton')}>Sign Out</button>
            </div>
          </>
        ) : (
          <>
            {/* Show Sign In and Get Started if user is not logged in */}
            <div className={b('LogIn')}>
              <Link to="/login" className={b('LoginButton')}>Sign In</Link>
            </div>
            <div className={b('GetStarted')}>
              <Link to="/signup" className={b('Button')}>Get Started</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TopBar;
