import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { IImages } from 'common/types/images';

export interface IShowImagesState {
  showImages: IImages | null;
  fetchStatus: FetchStatus | null;
}

export enum ActionTypes {
  FETCH_SHOW_IMAGES_START = '[show] fetch show images start',
  FETCH_SHOW_IMAGES_SUCCESS = '[show] fetch show images success',
  FETCH_SHOW_IMAGES_FAILURE = '[show] fetch show images failure',
  CLEAR_SHOW_IMAGES = '[show] clear show images',
}

export interface IFetchShowImagesStartAction extends Action {
  type: ActionTypes.FETCH_SHOW_IMAGES_START;
  payload: {
    seriesId: number;
    seasonNumber: number;
    episodeNumber: number;
  }
}

export interface IFetchShowImagesSuccessAction extends Action {
  type: ActionTypes.FETCH_SHOW_IMAGES_SUCCESS;
  payload: IImages;
}

export interface IFetchShowImagesFailureAction extends Action {
  type: ActionTypes.FETCH_SHOW_IMAGES_FAILURE;
}

export interface IClearShowImagesAction extends Action {
  type: ActionTypes.CLEAR_SHOW_IMAGES;
}

export type IFetchShowImagesActions =
  | IFetchShowImagesStartAction
  | IFetchShowImagesSuccessAction
  | IFetchShowImagesFailureAction
  | IClearShowImagesAction;