import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { IShowCredits } from 'common/types/show-credits';

export interface IShowCreditsState {
  showCredits: IShowCredits | null;
  fetchStatus: FetchStatus | null;
}

export enum ActionTypes {
  FETCH_SHOW_CREDITS_START = '[show] fetch show credits start',
  FETCH_SHOW_CREDITS_SUCCESS = '[show] fetch show credits success',
  FETCH_SHOW_CREDITS_FAILURE = '[show] fetch show credits failure',
  CLEAR_SHOW_CREDITS = '[show] clear show credits',
}

export interface IFetchShowCreditsStartAction extends Action {
  payload: number;
  type: ActionTypes.FETCH_SHOW_CREDITS_START;
}

export interface IFetchShowCreditsSuccessAction extends Action {
  type: ActionTypes.FETCH_SHOW_CREDITS_SUCCESS;
  payload: IShowCredits;
}

export interface IFetchShowCreditsFailureAction extends Action {
  type: ActionTypes.FETCH_SHOW_CREDITS_FAILURE;
}

export interface IClearShowCreditsAction extends Action {
  type: ActionTypes.CLEAR_SHOW_CREDITS;
}

export type IFetchShowCreditsActions =
  | IFetchShowCreditsStartAction
  | IFetchShowCreditsSuccessAction
  | IFetchShowCreditsFailureAction
  | IClearShowCreditsAction;
