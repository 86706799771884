import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { ISearch } from 'common/types/search';
import { IMovie } from 'common/types/movie';

export interface ItopRatedContentState {
  topRatedMovie: ISearch<IMovie> | null;
  fetchStatus: FetchStatus | null;
  topRatedSearchPage: number;
}

export enum ActionTypes {
  FETCH_TOPRATED_MOVIES_START = '[movie] fetch topRated movies start',
  FETCH_TOPRATED_MOVIES_SUCCESS = '[movie] fetch topRated movies success',
  FETCH_TOPRATED_MOVIES_FAILURE = '[movie] fetch topRated movies failure',

  SET_TOPRATED_SEARCH_PAGE = '[movie] set topRated search page',
  CLEAR_TOPRATED_SEARCH_PAGE = '[movie] clear topRated search page',
}

export interface IFetchtopRatedContentStartAction extends Action {
  type: ActionTypes.FETCH_TOPRATED_MOVIES_START;
}

export interface IFetchtopRatedContentSuccessAction extends Action {
  type: ActionTypes.FETCH_TOPRATED_MOVIES_SUCCESS;
  payload: {
    movies: ISearch<IMovie>;
    shouldConcat?: boolean;
  };
}

export interface IFetchtopRatedContentFailureAction extends Action {
  type: ActionTypes.FETCH_TOPRATED_MOVIES_FAILURE;
}

export interface ICleartopRatedSearchPage extends Action {
  type: ActionTypes.CLEAR_TOPRATED_SEARCH_PAGE;
}

export interface ISettopRatedSearchPage extends Action {
  type: ActionTypes.SET_TOPRATED_SEARCH_PAGE;
  payload: ItopRatedContentState['topRatedSearchPage'];
}

export type IFetchtopRatedContentActions =
  | IFetchtopRatedContentStartAction
  | IFetchtopRatedContentSuccessAction
  | IFetchtopRatedContentFailureAction
  | ICleartopRatedSearchPage
  | ISettopRatedSearchPage;
