import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from 'react-router-dom';
import './PurchaseCredits.css'; // Make sure to create a corresponding CSS file
import { auth } from 'components/ui/SignUp/firebaseconfig'; // Ensure this is the correct path to your Firebase auth configuration
import { useAuthState } from 'react-firebase-hooks/auth';

// loadStripe outside of a component to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe('pk_live_51JvHbLFgh84EppbbDS2T2V7QvlCUOEgGSbElIJEaHDH4bc6AI7Ocm0UvSK0SdHXGu5FQKEELlfUjYgTDwWnC8QR800exDskLA3');
const SERVER_URL = 'https://recapflix-e82c0158527d.herokuapp.com';

const PurchaseCreditsPage = () => {
    
    const [user] = useAuthState(auth);
    
    const creditOptions = [
        { credits: 10, price: 300 }, // Note: Stripe uses the smallest currency unit (e.g., cents for USD)
        { credits: 30, price: 500 },
        { credits: 150, price: 2000 },
    ];

    const handlePurchase = async (credits : any, price : any) => {
        // Get Stripe.js instance

        if (user ) {
        const stripe = await stripePromise;
        const idToken = await user.getIdToken();
        const userEmail = user.email; // Access the email property from the user object
        if (!stripe) {
            return; // Exit the function if stripe is null
        }
        // Call your backend to create the Checkout Session
        const response = await fetch(`${SERVER_URL}/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
             'Authorization': `Bearer ${idToken}` 

            },
            body: JSON.stringify({ creditAmount: credits, email: userEmail }),
        });
        const session = await response.json();
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        } } else {
            // Handle the case where the user is not logged in
        }
    };

    return (
        <div className="purchase-page-container">
            <div className="content">
                <h1>Purchase Credits</h1>
                <h3>Pick the amount of credits you'd like to add to your account.</h3>
                <h3>Movie recap cost 2 credits and episode recaps cost 1!</h3>
                <div className="credit-options">
                {creditOptions.map((option, index) => (
    <div key={index} className={`credit-option ${index === 1 ? 'popular' : ''}`}>
        <h3>{option.credits} Credits</h3>
        {index === 1 && <p className="popular-badge">Most Popular!</p>}
        <p>${option.price / 100}</p> {/* Adjust the display to account for currency unit */}
        <button className="button5" onClick={() => handlePurchase(option.credits, option.price)}>Get Credits</button>
    </div>
))}

                </div>
                <Link to="/" className="button back-button">Back to Home</Link>
            </div>
        </div>
        
    );
};

export default PurchaseCreditsPage;
