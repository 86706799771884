import { ActionTypes, ISubtitlesState, SubtitleActions } from './types';

const initialState: ISubtitlesState = {
  searchResults: null,
  downloadResults: null,
  downloadStatus: null,
  searchStatus: null,
  convertedText: '',
  convertStatus: null // Add this line
};

function reducer(state: ISubtitlesState = initialState, action: SubtitleActions): ISubtitlesState {
  switch (action.type) {
    case ActionTypes.SEARCH_SUBTITLES_START:
      return { ...state, searchStatus: 'pending' };
    case ActionTypes.SEARCH_SUBTITLES_SUCCESS:
      // TypeScript now knows action.payload is ISubtitle[]
      const sortedSubtitles = action.payload.sort((a, b) => b.attributes.download_count - a.attributes.download_count);
      return { ...state, searchResults: sortedSubtitles, searchStatus: 'success' };
    case ActionTypes.SEARCH_SUBTITLES_FAILURE:
      return { ...state, searchStatus: 'failure' };
    case ActionTypes.SEARCH_TV_SUBTITLES_START:
      return { ...state, searchStatus: 'pending' };
    case ActionTypes.SEARCH_TV_SUBTITLES_SUCCESS:
      // TypeScript now knows action.payload is ISubtitle[]
      const sortedTVsubtitles = action.payload.sort((a, b) => b.attributes.download_count - a.attributes.download_count);
      return { ...state, searchResults: sortedTVsubtitles, searchStatus: 'success' };
    case ActionTypes.SEARCH_TV_SUBTITLES_FAILURE:
      return { ...state, searchStatus: 'failure' };
    case ActionTypes.DOWNLOAD_SUBTITLE_START:
      return { ...state, downloadStatus: 'pending' };
    case ActionTypes.DOWNLOAD_SUBTITLE_SUCCESS:
      return { ...state, downloadStatus: 'success' , downloadResults: action.payload};
    case ActionTypes.DOWNLOAD_SUBTITLE_FAILURE:
      return { ...state, downloadStatus: 'failure' };
      case ActionTypes.CONVERT_SUBTITLE_START:
        return { ...state, convertStatus: 'pending' };
      case ActionTypes.CONVERT_SUBTITLE_SUCCESS:
        return { ...state, convertStatus: 'success', convertedText: action.payload };
      case ActionTypes.CONVERT_SUBTITLE_FAILURE:
        return { ...state, convertStatus: 'failure' };
    case ActionTypes.CLEAR_SUBTITLES:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
