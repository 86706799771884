import React, { FunctionComponent } from 'react';
import { cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';

import AppIcon from 'components/ui/AppIcon'; // Assuming you don't need AppLink for AppIcon
import recapflix from 'src/assets/img/webp/recapflix.webp';

import './AppSocials.sass';

const b = cn('Socials');

interface IProps {
  className?: string;
}

const AppSocials: FunctionComponent<IProps> = ({ className }) => {
  return (
    <div className={classnames(b('Socials'), className)}>
      <ul className={b('List')}>
        <li className={b('Item')}>
          {/* Wrap the img in a standard anchor tag to link to the homepage */}
          <a href="/">
            <img src={recapflix} alt="Recapflix Logo" width={200} height={200} />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AppSocials;
