import { ApplicationState } from 'store/store';

export function gettopRatedContent(state: ApplicationState) {
  return state.movies.topRatedContent.topRatedMovie;
}

export function gettopRatedContentFetchStatus(state: ApplicationState) {
  return state.movies.topRatedContent.fetchStatus;
}

export function getCurrentPagetopRatedContent(state: ApplicationState) {
  return state.movies.topRatedContent.topRatedSearchPage;
}

export function hasMorePagesWithtopRatedContent(state: ApplicationState) {
  if (state.movies.topRatedContent.topRatedMovie) {
    return state.movies.topRatedContent.topRatedSearchPage < state.movies.topRatedContent.topRatedMovie.total_pages;
  }

  return false;
}
