import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps, useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
const SERVER_URL = 'https://recapflix-e82c0158527d.herokuapp.com';

interface Item {
  tmdb_id: number;
  season?: number; // Optional property for the season number
  episode?: number; // Optional property for the episode number
  // Add other properties as needed
}


interface ContentProtectedRouteProps extends RouteProps {
  recapType: 'movie' | 'show';
}

interface IRouteParams {
  id: string;
  seasonNumber?: string;
  episodeNumber?: string;
}

const ContentProtectedRoute: React.FC<ContentProtectedRouteProps> = ({ children, recapType, ...rest }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [hasPurchased, setHasPurchased] = useState(false);
  const [checkComplete, setCheckComplete] = useState(false);
  const params = useParams<IRouteParams>();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      setError(null); // Reset any previous errors
    }, (error) => {
      setError(error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    // Directly check session storage for temporary access
    let temporaryAccessKey;
    if (recapType === 'movie') {
     temporaryAccessKey = `access_${params.id}_`;}
    else {
       temporaryAccessKey = `access_${params.id}_${params.seasonNumber}_${params.episodeNumber}`;}
    const hasTempAccess = sessionStorage.getItem(temporaryAccessKey) === 'true';
    const checkPurchase = async () => {
      if (hasTempAccess) {
        setHasPurchased(true);
        setCheckComplete(true);
        return;
      }

      if (!user) {
        setCheckComplete(true);
        return;
      }

      try {
        const idToken = await user.getIdToken();
        const response = await fetch(`${SERVER_URL}/api/user-recaps`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
        });

        if (response.ok) {
          const data: Item[] = await response.json();
          const numericId = Number(params.id);
          let hasAccess = false;

          if (recapType === 'movie') {
            hasAccess = data.some(item => item.tmdb_id === numericId);
          } else if (recapType === 'show' && params.seasonNumber && params.episodeNumber) {
            const seasonNumber = Number(params.seasonNumber);
            const episodeNumber = Number(params.episodeNumber);
            hasAccess = data.some(item => item.tmdb_id === numericId && item.season === seasonNumber && item.episode === episodeNumber);
          }

          setHasPurchased(hasAccess);
        } else {
        }
      } catch (error) {
      }

      setCheckComplete(true);
    };

    if (!loading) {
      checkPurchase();
    }
  }, [user, loading, params.id, params.seasonNumber, params.episodeNumber, recapType]);

  if (loading || !checkComplete) {
    return <div>Loading...</div>;
  }

  if (error || !user) {
    return <Redirect to="/login" />;
  }

  if (!hasPurchased) {
    let redirectUrl = `/movie/${params.id}`;
    if (recapType === 'show') {
      redirectUrl = `/show/${params.id}`;
      if (params.seasonNumber && params.episodeNumber) {
        redirectUrl += ``;
      }
    }
    return <Redirect to={redirectUrl} />;
  }
  

  return <>{children}</>;
};

export default ContentProtectedRoute;
