import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, SearchSubtitlesStartAction, SearchTVSubtitlesStartAction, DownloadSubtitleStartAction, ConvertSubtitlesStartAction } from './types';
import { searchSubtitlesSuccess, searchSubtitlesFailure, downloadSubtitleSuccess, downloadSubtitleFailure, convertSubtitlesSuccess, convertSubtitlesFailure } from './actions';
import { useSearchSubtitlesByTMDB, useSearchTVSubtitlesByTMDB, downloadAndConvertSubtitle } from '~/services/osService';
import Notifications from 'react-notification-system-redux';
import { ISubtitle, ISubtitleFile } from 'common/types/subtitle';

// Assuming openSubsService has methods searchSubtitlesByTMDB and downloadSubtitle

function* searchSubtitlesSaga(action: SearchSubtitlesStartAction): Generator {
  try {
    const subtitles = yield call(useSearchSubtitlesByTMDB, action.payload);
    const formattedSubs = subtitles as ISubtitle[];
    yield put(searchSubtitlesSuccess(formattedSubs));
  } catch (error) {
    yield put(searchSubtitlesFailure());
    yield put(
      Notifications.error({
        title: 'Subtitle Search Error',
        message: 'Failed to search subtitles, please try again.',
        autoDismiss: 3,
      })
    );
  }
}


function* searchTVSubtitlesSaga(action: SearchTVSubtitlesStartAction): Generator {
  try {
    // Assuming action.payload correctly deconstructs tmdbId, season, episode from the dispatched action
    const { tmdbId, season, episode } = action.payload;
    // Pass tmdbId, season, episode as separate arguments to the call effect
    const subtitles = yield call(useSearchTVSubtitlesByTMDB, tmdbId, season, episode);
    const formattedSubs = subtitles as ISubtitle[];
    yield put(searchSubtitlesSuccess(formattedSubs));
  } catch (error) {
    yield put(searchSubtitlesFailure());
    yield put(
      Notifications.error({
        title: 'TV Subtitle Search Error',
        message: 'Failed to search TV subtitles, please try again.',
        autoDismiss: 3,
      })
    );
  }
}


function* downloadSubtitleSaga(action: DownloadSubtitleStartAction): Generator {
  try {
    // Directly use the result from the service call, which now matches ISubtitleFile structure
    const subtitleFile = yield call(downloadAndConvertSubtitle, action.payload);
    const formattedSubFile = subtitleFile as ISubtitleFile;
    // Dispatch the success action with the obtained ISubtitleFile
    yield put(downloadSubtitleSuccess(formattedSubFile));
  } catch (error) {
    yield put(downloadSubtitleFailure());
    yield put(
      Notifications.error({
        title: 'Subtitle Download Error',
        message: 'Failed to download subtitle, please try again.',
        autoDismiss: 3,
      })
    );
  }
}

function* convertSubtitleSaga(action: ConvertSubtitlesStartAction) {
  try {
    const plainTextContent: string = yield call(downloadAndConvertSubtitle, action.payload);
    // Assuming you want to handle the plain text content further or store it
    yield put(convertSubtitlesSuccess(plainTextContent));
  } catch (error) {
    yield put(convertSubtitlesFailure());
    yield put(
      Notifications.error({
        title: 'Conversion Error',
        message: 'Failed to download and convert subtitle. Please try again.',
        autoDismiss: 3,
      })
    );
  }
}


export default function* subtitlesSaga() {
  yield all([
    takeLatest(ActionTypes.SEARCH_SUBTITLES_START, searchSubtitlesSaga),
    takeLatest(ActionTypes.SEARCH_TV_SUBTITLES_START, searchTVSubtitlesSaga),
    takeLatest(ActionTypes.DOWNLOAD_SUBTITLE_START, downloadSubtitleSaga),
    takeLatest(ActionTypes.CONVERT_SUBTITLE_START, convertSubtitleSaga), // Listen for the new action
  ]);
}
