import { Action } from 'redux';
import { FetchStatus } from 'common/types/fetch-status';
import { ISeason } from 'common/types/season';

export interface ISeasonsState {
  season: ISeason | null;
  fetchStatus: FetchStatus | null;
}

export enum ActionTypes {
  FETCH_SEASONS_START= '[season] fetch seasons start',
  FETCH_SEASONS_SUCCESS = '[season] fetch seasons success',
  FETCH_SEASONS_FAILURE = '[season] fetch seasons failure',
}

export interface IFetchSeasonsStartAction extends Action {
  type: ActionTypes.FETCH_SEASONS_START;
  payload: {
    seriesId: number;
    seasonNumber: number;
  };}

export interface IFetchSeasonsSuccessAction extends Action {
  type: ActionTypes.FETCH_SEASONS_SUCCESS;
  payload: ISeason;

}

export interface IFetchSeasonsFailureAction extends Action {
  type: ActionTypes.FETCH_SEASONS_FAILURE;
}

export type IFetchSeasonsActions =
  | IFetchSeasonsStartAction
  | IFetchSeasonsSuccessAction
  | IFetchSeasonsFailureAction;
