import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionTypes, IFetchShowCreditsStartAction } from 'store/shows/credits/types';
import TMDbService from '~/services/tmdbService';
import { fetchShowCreditsFailure, fetchShowCreditsSuccess } from 'store/shows/credits/actions';
import Notifications from 'react-notification-system-redux';

const API = new TMDbService();

function* fetchShowDetailsSaga(action: IFetchShowCreditsStartAction): Generator {
  try {
    const showCredits: any = yield call(API.getShowCredits, action.payload);
    yield put(fetchShowCreditsSuccess(showCredits));
  } catch (error) {
    yield put(fetchShowCreditsFailure());
    // Assuming Notifications.error is properly set up elsewhere
    yield put(
      Notifications.error({
        title: 'Movie credits',
        message: 'Error during request, please reload page',
        autoDismiss: 3,
      })
    );
  }
}

export default function*() {
  yield all([takeLatest(ActionTypes.FETCH_SHOW_CREDITS_START, fetchShowDetailsSaga)]);
}
